<template>
	<div>
		<v-card style="margin-top: 15px;">
			<s-toolbar label="Imprimir QR" dark color="#8e8f91"></s-toolbar>
			<v-container>
				<v-row style="margin:auto" justify="center">
					<v-col cols="12" md="3" lg="3">
						<s-date @input="inputDate($event)"> </s-date>
					</v-col>
					<v-col cols="12" md="3" lg="3">
						<s-select
							:items="itemsNumberGuide"
							item-value="RcfID"
							full
							autocomplete
							item-text="RcfNumberHarvestGuide"
							label="Seleccione Nro. Guia de cosecha"
							v-model="RcfID"
							@input="inputNumberGuide($event)"
						></s-select>
					</v-col>
					<v-col cols="12" md="3" lg="3">
						<s-select
							:items="itemsGang"
							item-value="RcfGngID"
							item-text="FrmName"
							label="Seleccione Capataz"
							return-object
							v-model="RcfGngID"
							@input="inputForeman($event)"
						></s-select>
					</v-col>
				</v-row>
				<hr style="margin: 10px 0px">
				<v-row justify="center" style="margin:auto">
					<v-col lg="3"> <b>Capataz:</b> {{ viewItemsGang.FrmName }} </v-col>
					<v-col lg="3">
						<b>Productor:</b> {{ viewItemsGang.PrdCardName }}
					</v-col>
					<v-col lg="3"> <b>Lote: </b>{{ viewItemsGang.FltName }} </v-col>
				</v-row>
				<v-row justify="center" style="margin-top: -20px; font-size: smaller;">
					<v-col lg="3">
						<b>Tipo Cultivo:</b> {{ viewItemsGang.TypeCropName }}
					</v-col>
					<v-col lg="3">
						<b>Cultivo:</b> {{ viewItemsGang.TypeCultiveName }}
					</v-col>
					<v-col lg="3"> <b>Variedad: </b>{{ viewItemsGang.VrtName }} </v-col>
				</v-row>

				<v-row style="margin-top: 20px;">
					<s-toolbar
						label="Listado de Pesos"
						dark
						:color="'warning'"
						class="mr-3 ml-3"
						style="display: flex;"
						print
						@print="printQR()"
					></s-toolbar>
					<v-container style="padding-top: 0%;">
						<v-row>
							<v-col>
								<v-data-table
									disable-sort
									item-key="WpbID"
									:headers="headersWeighed"
									:items="itemsWeighed"
									v-model="selectedWeighed"
									dense
									hide-default-footer
									:items-per-page="-1"
									@click:row="rowClickWeighed"
								>
									<template v-slot:item.xCount={item}>
										<v-chip
											x-small
											
											:color="item.xCount >= 1 ? 'info' : 'error'"
										>
											{{item.xCount >= 1 ? 'Abastecido' : 'Sin Abast.'}}
										</v-chip>
									</template>								
								</v-data-table>
							</v-col>
						</v-row>
					</v-container>
				</v-row>
				<v-row justify="center" style="display: grid;">
					<v-col cols="12">
						<qr-code :text="textQR" error-level="H"> </qr-code>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</div>
</template>

<script>
	import _sWeighedByBatch from "../../../services/FreshProduction/WeighedByBatchService";
	import _sReceptionFresh from "../../../services/FreshProduction/ReceptionFreshService";
	import qrCode from "vue-qr-generator";
	import signalr from "signalr";
import { watch } from "vue";

	export default {
		props: {

			ship: {
				type: Number,
				default: 1,
			},
		},
		components: { qrCode },
		data: () => ({
			itemsNumberGuide: [],
			itemsGang: [],
			itemsWeighed: [],
			headersWeighed: [
				{ text: "Fecha/Hora", value: "WpbDate", width: 150 },
				{ text: "Nro. Palet", value: "WpbNumberPallet", width: 80 },
				{ text: "Cant. Jabas", value: "WpbJabasQuantity", width: 100 },
				{ text: "Peso Jaba", value: "WpbWeightJaba", width: 100 },
				{ text: "Peso Parihuela", value: "WpbWeightPallet", width: 100 },
				{ text: "Peso Bruto", value: "WpbWeightGross", width: 150 },
				{ text: "Peso Neto", value: "WpbWeightNet", width: 100 },
				{
					text: "Peso Prom. Jaba",
					value: "WpbWeightAverageJaba",
					width: 100,
				},
				{ text: "Estado", value: "xCount", width: 100}
			],
			RcfGngID: {},
			viewItemsGang: {},
			RcfID: 0,
			selectedWeighed: [],
			textQR: "Sin datos",
		}),

		methods: {
			inputNumberGuide(RcfID) {
				this.itemsGang = [];
				this.RcfGngID = {};

				if (RcfID !== 0 || RcfID !== undefined) {
					//Cargar todos los capataz pertenecientes a es Guia de Cosecha seleccionada
					_sReceptionFresh
						.GetGang({ RcfID: RcfID }, this.$fun.getUserID())
						.then(r => {
							this.itemsGang = r.data;
							this.itemsWeighed = [];
							this.textQR = "Sin datos";
						});
				}
			},

			//Obtener Pesadas
			getWeighedByBatch() {
				_sWeighedByBatch
					.getWeighedBatch(
						{
							RcfID: this.viewItemsGang.RcfID, //this.viewItemsGang.RcfID,
							RcfGngID: this.viewItemsGang.RcfGngID, //this.viewItemsGang.RcfGngID,
							TypeDoor: 0,
							xPrint: 1,
						},
						this.$fun.getUserID(),
					)
					.then(r => {
						this.itemsWeighed = r.data; //Registros de Pesada
						this.itemsWeighed.forEach(element => {
							element.WpbDate = this.$moment(element.WpbDate).format(
								this.$const.FormatDateTimeDB,
							);
						});
						console.log("this.itemsWeighed", this.itemsWeighed);
					});
			},

			inputForeman(objForeman) {
				if (objForeman !== undefined) {
					this.viewItemsGang = objForeman;
					console.log("dddddddddd",this.viewItemsGang);
					this.getWeighedByBatch(); //Cargamos la Pesadas a ese Capataz
				}
			},

			rowClickWeighed: function(item, row) {
				this.selectedWeighed = [item];
				console.log(this.selectedWeighed);
				var cadTextQR =
					this.selectedWeighed[0].RcfID.toString() +
					", " +
					this.selectedWeighed[0].WpbID.toString() +
					", " +
					this.selectedWeighed[0].WpbJabasQuantity +
					", " +
					this.selectedWeighed[0].WpbNumberPallet +
					", LP-" +
					this.selectedWeighed[0].RcfID.toString() +
					", Cant. Jabas: " +
					this.selectedWeighed[0].WpbJabasQuantity +
					", PP. Jaba: " +
					this.selectedWeighed[0].WpbWeightAverageJaba +
					", PN: " +
					this.selectedWeighed[0].WpbWeightNet +
					", PB: " +
					this.selectedWeighed[0].WpbWeightGross +
					", " +
					this.selectedWeighed[0].WpbDate +
					", " +
					this.selectedWeighed[0].TypeDoorName;

				this.textQR = cadTextQR;
			},

			inputDate(val) {
				(this.itemsNumberGuide = []),
					_sWeighedByBatch
						.getHarvestGuide(
							{ xPrint: 1, xDate: val, TypeShip: this.ship },
							this.$fun.getUserID(),
						)
						.then(r => {
							if (r.data.length > 0) {
								this.itemsNumberGuide = r.data;
								console.log("itemsNumberGuide sa", r.data);
							}
						});
			},

			printQR() {
				console.log("QRRR", this.selectedWeighed);
				if(this.selectedWeighed.length===0){
					this.$fun.alert("Seleccione un item del Listado", "warning" );
					return;
				}
				 
				var img = document.querySelector("img");

				var Pagelink = "about:blank";
				var pwa = window.open(Pagelink, "_new");
				pwa.document.open();
				pwa.document.write(
					"<html><head><scri" +
						"pt>function step1(){\n" +
						"setTimeout('step2()', 2);}\n" +
						"function step2(){window.print();window.close()}\n" +
						"</scri" +
						"pt><style media='print'>  @media print  { @page { margin-left: 4cm; {} </style></head><body onload='step1()'> \n" +
						"<div class='col col-12 justify-center'>",
				);

				pwa.document.write(
					"<div style='margin: 20px 20px 20px 20px;'><img alt='Scan me!' style='display: block;' src='" +
						img.currentSrc +
						"' /></div>",
				);
				pwa.document.write("<br><br><div ><table border='0'>");

				pwa.document.write(
					"<tr><td>Pallet Nro: </td><td>" +
						this.selectedWeighed[0].WpbNumberPallet + " - " +
						this.selectedWeighed[0].WpbID +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Lote Produccion: </td><td>" +
						this.RcfGngID.RcfID + " - " + this.RcfGngID.RcfCode  +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Capataz </td><td>" +
						this.RcfGngID.FrmName +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Guia Cosecha </td><td>" +
						this.RcfGngID.RcfNumberReferralGuide +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Productor: </td><td>" +
						this.RcfGngID.PrdCardName +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Fundo: </td><td>" +
						this.RcfGngID.FagName + ' || ' + this.RcfGngID.FltName +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Localidad: </td><td>" +
						this.selectedWeighed[0].TypeZonLocationName +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Cultivo: " +
						this.RcfGngID.TypeCultiveName +
						" </td><td> Variedad: " +
						this.RcfGngID.VrtName +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Tipo Cultivo: </td><td>" +
						this.RcfGngID.TypeCropName +
						" </td></tr>",
				);

				pwa.document.write(
					"<tr><td>Peso Promedio Jaba: </td><td>" +
						this.selectedWeighed[0].WpbWeightAverageJaba +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Peso Bruto: " +
						this.selectedWeighed[0].WpbWeightGross +
						" </td><td> Peso Neto: " +
						this.selectedWeighed[0].WpbWeightNet +
						" </td></tr>",
				);
				pwa.document.write(
					"<tr><td>Puerta: </td><td>" +
						this.selectedWeighed[0].TypeDoorName +
						" </td></tr>",
				);

				pwa.document.write(
					"<tr><td>Certificaciones: </td><td>" +
						this.RcfGngID.TcDescription +
						" </td></tr>",
				);

				pwa.document.write(
					"<tr><td>Cant. Jabas: </td><td>" +
						this.selectedWeighed[0].WpbJabasQuantity +
						" </td></tr>",
				);

				pwa.document.write("</table></div>");

				pwa.document.write("</div></body></html>");
				pwa.document.close();
			},
		},

		mounted() {
			let recaptchaScript = document.createElement("script");
			recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
			document.head.appendChild(recaptchaScript);

			recaptchaScript.addEventListener("load", () => {
				$.connection.hub.url = this.$const.URL + "/signalr";
				var chat = $.connection.notification;
				chat.client.SendMessageSupplyCalibrator = group => {
					this.getWeighedByBatch()
				};

				$.connection.hub.start({ jsonp: true }).done(e => {
					chat.invoke("addToGroup", "updateWaitingsupplycalibrator");
				});
			});
		},
	};
</script>

<style></style>
